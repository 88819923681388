import {
  AfterViewChecked,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  NgZone,
  Output,
  ViewChild
} from '@angular/core';
import { DropdownItem } from '../models';
import { BsDropdownDirective } from 'ngx-bootstrap/dropdown';

enum Trigger {
  CLICK = 'click',
  HOVER = 'hover'
}

@Component({
  selector: 'vl-dropdown',
  styleUrls: ['dropdown.component.scss'],
  templateUrl: 'dropdown.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class VlDropdownComponent implements AfterViewChecked {
  @ViewChild('dropdown', { read: BsDropdownDirective, static: false }) dropdown: BsDropdownDirective;
  @ViewChild('toggleElement') toggleElement: ElementRef;
  @ViewChild('dropdownMenu', { static: false }) dropdownMenu: ElementRef;
  @Input() menuItems: DropdownItem<unknown>[];
  @Input() autoClose?: boolean = true;
  @Input() insideClick?: boolean = false;
  @Input() dropup?: boolean = false;
  @Input() autoDropup?: boolean = false;
  @Input() dropdownHeight?: number = 0;
  @Input() appendToBody?: boolean = true;
  @Input() isWide?: boolean;
  @Input() isDisabled?: boolean;
  @Input() dividerAt?: number;
  @Input() trigger? = Trigger.CLICK;
  @Input() dropdownId? = '';
  @Input() isRightAligned?: boolean;

  Trigger = Trigger;

  @Output() private handleMenuItemSelect = new EventEmitter<DropdownItem>();
  @Output() private toggleDropdown = new EventEmitter<boolean>();

  highlightedItemIndex = -1;
  private highlightedOptionUpdated = false;
  private searchChars = '';
  private searchTimeoutHandle;

  @HostListener('document:keydown', ['$event']) onKeydownHandler(event: KeyboardEvent) {
    this.onKeydown(event);
  }

  constructor(private ngZone: NgZone) {}

  ngAfterViewChecked() {
    if (this.highlightedOptionUpdated) {
      const el = this.dropdownMenu.nativeElement.querySelector('.vl-dropdown-menu__item--highlighted');
      el?.scrollIntoView({ block: 'nearest' });

      this.highlightedOptionUpdated = false;
    }
  }

  onMenuItemSelect(item: DropdownItem): void {
    this.handleMenuItemSelect.emit(item);
  }

  onToggleButtonClick(): void {
    if (this.dropdown.isOpen) {
      this.dropdown.hide();
    } else {
      if (this.autoDropup) {
        const bounding = this.toggleElement.nativeElement.getBoundingClientRect();
        const windowHeight = window.innerHeight || document.documentElement.clientHeight;
        const dropup = bounding.bottom + this.dropdownHeight > windowHeight;
        this.dropdown.dropup = dropup;
        this.dropdown.placement = `${dropup ? 'top' : 'bottom'} ${this.isRightAligned ? 'right' : 'left'}`;
      }
      this.dropdown.show();
    }
  }

  dropdownShownHandler(): void {
    this.ngZone.run(() => {
      setTimeout(() => {
        // https://veloce.atlassian.net/browse/CRQ-884
        // https://veloce.atlassian.net/browse/CRQ-996
        // this hack is needed to run extra change detection
        // because when component is used in runtime it doesn't always open
      }, 50);
    });
    this.toggleDropdown.emit(true);
  }

  dropdownHiddenHandler(): void {
    this.toggleDropdown.emit(false);
  }

  show(): void {
    this.onToggleButtonClick();
  }

  trackById(_: number, item: DropdownItem<any>): unknown {
    return item.id;
  }

  private findNextEnabledOptionIndex(i: number): number {
    if (!this.menuItems.some(({ isUnavailable }) => !isUnavailable)) {
      return -1;
    }

    let nextIndex = i + 1;
    if (nextIndex >= this.menuItems.length) {
      nextIndex = 0;
    }

    if (this.menuItems[nextIndex].isUnavailable) {
      return this.findNextEnabledOptionIndex(nextIndex);
    }
    return nextIndex;
  }

  private findPrevEnabledOptionIndex(i: number): number {
    if (!this.menuItems.some(({ isUnavailable }) => !isUnavailable)) {
      return -1;
    }

    let nextIndex = i - 1;
    if (nextIndex < 0) {
      nextIndex = this.menuItems.length - 1;
    }

    if (this.menuItems[nextIndex].isUnavailable) {
      return this.findPrevEnabledOptionIndex(nextIndex);
    }
    return nextIndex;
  }

  private search(event: KeyboardEvent): void {
    this.searchChars += event.key;

    const searchIndex =
      this.highlightedItemIndex != null
        ? this.searchOptionIndexInRange(this.searchChars, this.highlightedItemIndex + 1, this.menuItems.length)
        : -1;
    const newIndex = this.searchOptionIndexInRange(this.searchChars, searchIndex, this.menuItems.length);

    this.highlightedItemIndex = newIndex;

    if (newIndex >= 0) {
      this.highlightedOptionUpdated = true;
    }

    clearTimeout(this.searchTimeoutHandle);
    this.searchTimeoutHandle = setTimeout(() => (this.searchChars = ''), 300);
  }

  onKeydown(event: KeyboardEvent): void {
    if (this.isDisabled || !this.menuItems?.length) {
      return;
    }

    switch (event.key) {
      case 'ArrowDown':
      case 'ArrowUp':
        if (!this.dropdown.isOpen) {
          this.show();
        } else {
          const newIndex =
            event.key === 'ArrowDown'
              ? this.findNextEnabledOptionIndex(this.highlightedItemIndex)
              : this.findPrevEnabledOptionIndex(this.highlightedItemIndex);

          if (newIndex !== this.highlightedItemIndex) {
            this.highlightedItemIndex = newIndex;
            this.highlightedOptionUpdated = true;
          }
        }
        event.preventDefault();
        break;
      case 'Enter':
        if (this.dropdown.isOpen && this.highlightedItemIndex >= 0) {
          this.onMenuItemSelect(this.menuItems[this.highlightedItemIndex] as DropdownItem);
        }
      case 'Escape':
      case 'Tab':
        this.dropdown.hide();
        break;
      default:
        if (!event.metaKey) {
          this.search(event);
        }
        break;
    }
  }

  private searchOptionIndexInRange(searchValue: string, start: number, end: number): number {
    return this.menuItems.findIndex(({ displayValue, isUnavailable }, index) => {
      return (
        index >= start &&
        index <= end &&
        !isUnavailable &&
        typeof displayValue === 'string' &&
        displayValue.toLowerCase().startsWith(searchValue)
      );
    });
  }
}
