import { Directive } from '@angular/core';
import { ExcelService, GenerateAndStyleCell, GenerateAndStyleOpt } from '../../services/excel.service';
import { FileDownloadService } from '../../services/file-download.service';

@Directive({
  selector: '[vlSpreadsheet]',
  exportAs: 'spreadsheetApi',
  providers: [ExcelService]
})
export class SpreadsheetDirective {
  constructor(private service: ExcelService, private fileDownloadService: FileDownloadService) {}

  async generate(rows: GenerateAndStyleCell[][], opt: GenerateAndStyleOpt): Promise<void> {
    const blob = await this.service.generateAndStyle(rows, opt);
    this.fileDownloadService.downloadFile(opt.fileName + '.xlsx', blob);
  }
}
