import { AgGridModule } from 'ag-grid-angular';
import iziToast from 'izitoast';
import { DragulaModule } from 'ng2-dragula';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { TooltipDirective, TooltipModule } from 'ngx-bootstrap/tooltip';
import { MonacoEditorModule, NgxMonacoEditorConfig } from 'ngx-monaco-editor';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {
  AddFilterComponent,
  AddressSearchComponent,
  ButtonComponent,
  CheckboxCellRendererComponent,
  CheckboxComponent,
  CodeEditorComponent,
  CommentCellRendererComponent,
  ContentCellRendererComponent,
  ControlGroupComponent,
  DatepickerComponent,
  DefaultFilterComponent,
  DropdownCellRendererComponent,
  DropdownComponent,
  EmptyPageComponent,
  FileChooserComponent,
  FilterComponent,
  FilterListComponent,
  GroupRendererComponent,
  InputFilterComponent,
  InputGroupComponent,
  LabelComponent,
  LoaderComponent,
  MassEditComponent,
  MenuCellRendererComponent,
  ModalBaseComponent,
  MultiselectComponent,
  RadioComponent,
  ReleaseVersionComponent,
  ScrollContainerComponent,
  SelectionCheckboxCellRendererComponent,
  SliderComponent,
  SubtotalCellRendererComponent,
  TableComponent,
  TableConfigurationComponent,
  TemplateAdminComponent,
  TemplateEditComponent,
  TemplateExpandableAreaComponent,
  TemplateListAdminComponent,
  TemplateListComponent,
  TemplatePropertiesComponent,
  TemplatePropertyRendererComponent,
  TemplateTabComponent,
  TextCellEditorComponent,
  ToastComponent,
  TooltipCellRendererComponent,
  TypeaheadComponent,
  DemoCheckboxCellRendererComponent,
  DemoCommentCellRendererComponent,
  DemoContentCellRenderComponent,
  DemoMenuRendererComponent,
  DemoSubtotalCellRendererComponent,
  DemoTooltipCellRendererComponent,
  DemoTableComponent,
  DemoTextCellEditorComponent,
  VlDropdownComponent,
  VlDatepickerComponent,
  VlTypeaheadComponent,
  VlInputGroupComponent
} from './components';
import { AddressSearchService } from './components/address-search/address-search.service';
import { ModelLang } from './components/code-editor/languages/model-lang';
import { ReleaseVersionService } from './components/release-version/release-version.service';
import {
  DropFileTargetDirective,
  ExportDirective,
  SpreadsheetParseDirective,
  SpreadsheetDirective
} from './directives';
import { Language, Toaster } from './models';
import { DateRangePipe, DropdownDisplayValuePipe, DynamicPipe, HighlightIntersectionPipe } from './pipes';
import { ConverterService } from './services/converter.service';
import { FileDownloadService } from './services/file-download.service';

declare global {
  interface Window {
    VELO_PUBLIC_PATH: string;
    VELO_API: string;
    VELO_KEY?: string;
  }
}
declare const monaco: any;

const monacoConfig: NgxMonacoEditorConfig = {
  onMonacoLoad(): void {
    monaco.languages.register({ id: Language.MODEL });
    monaco.languages.setMonarchTokensProvider(Language.MODEL, ModelLang);
  },

  baseUrl: window.VELO_PUBLIC_PATH ? `${window.VELO_PUBLIC_PATH}/assets` : '/assets'
};

@NgModule({
  imports: [
    AgGridModule.withComponents([
      CheckboxCellRendererComponent,
      CommentCellRendererComponent,
      SubtotalCellRendererComponent,
      TextCellEditorComponent,
      TooltipCellRendererComponent,
      DropdownCellRendererComponent,
      GroupRendererComponent,
      ContentCellRendererComponent
    ]),
    BsDatepickerModule.forRoot(),
    BsDropdownModule.forRoot(),
    CommonModule,
    DragulaModule.forRoot(),
    FormsModule,
    HttpClientModule,
    ReactiveFormsModule,
    TooltipModule.forRoot(),
    MonacoEditorModule.forRoot(monacoConfig)
  ],
  declarations: [
    AddFilterComponent,
    ButtonComponent,
    CheckboxCellRendererComponent,
    CheckboxComponent,
    CodeEditorComponent,
    CommentCellRendererComponent,
    ContentCellRendererComponent,
    DropdownCellRendererComponent,
    ControlGroupComponent,
    DatepickerComponent,
    DateRangePipe,
    DefaultFilterComponent,
    DropdownComponent,
    DropdownDisplayValuePipe,
    DynamicPipe,
    EmptyPageComponent,
    ExportDirective,
    FileChooserComponent,
    FilterComponent,
    FilterListComponent,
    GroupRendererComponent,
    HighlightIntersectionPipe,
    InputFilterComponent,
    InputGroupComponent,
    LabelComponent,
    LoaderComponent,
    MassEditComponent,
    MenuCellRendererComponent,
    ModalBaseComponent,
    MultiselectComponent,
    RadioComponent,
    ReleaseVersionComponent,
    ScrollContainerComponent,
    SelectionCheckboxCellRendererComponent,
    SliderComponent,
    SubtotalCellRendererComponent,
    TableComponent,
    TableConfigurationComponent,
    TemplateAdminComponent,
    TemplateEditComponent,
    TemplateExpandableAreaComponent,
    TemplateListAdminComponent,
    TemplateListComponent,
    TemplatePropertiesComponent,
    TemplatePropertyRendererComponent,
    TemplateTabComponent,
    TextCellEditorComponent,
    ToastComponent,
    TooltipCellRendererComponent,
    TypeaheadComponent,
    AddressSearchComponent,
    DropFileTargetDirective,
    DemoTableComponent,
    DemoTextCellEditorComponent,
    DemoCheckboxCellRendererComponent,
    DemoCommentCellRendererComponent,
    DemoContentCellRenderComponent,
    DemoMenuRendererComponent,
    DemoSubtotalCellRendererComponent,
    DemoTooltipCellRendererComponent,
    SpreadsheetParseDirective,
    SpreadsheetDirective,
    VlDropdownComponent,
    VlDatepickerComponent,
    VlTypeaheadComponent,
    VlInputGroupComponent
  ],
  exports: [
    AddFilterComponent,
    ButtonComponent,
    ControlGroupComponent,
    CheckboxComponent,
    DatepickerComponent,
    DefaultFilterComponent,
    DropdownComponent,
    EmptyPageComponent,
    ExportDirective,
    FileChooserComponent,
    FilterComponent,
    FilterListComponent,
    InputFilterComponent,
    InputGroupComponent,
    LabelComponent,
    LoaderComponent,
    MassEditComponent,
    ModalBaseComponent,
    MultiselectComponent,
    RadioComponent,
    ReleaseVersionComponent,
    ScrollContainerComponent,
    SliderComponent,
    TableComponent,
    TableConfigurationComponent,
    TemplateAdminComponent,
    ToastComponent,
    TypeaheadComponent,
    CodeEditorComponent,
    AddressSearchComponent,
    DropFileTargetDirective,
    DemoTableComponent,
    TooltipDirective,
    SpreadsheetParseDirective,
    SpreadsheetDirective
  ],
  providers: [
    { provide: 'Toaster', useValue: iziToast as Toaster },
    AddressSearchService,
    ConverterService,
    DateRangePipe,
    DropdownDisplayValuePipe,
    FileDownloadService,
    HighlightIntersectionPipe,
    ReleaseVersionService
  ]
})
export class VeloceElementsModule {}
